<template>
  <div>
    <hr style="size: 2px; padding-top: 80px" />
    <section style="padding: 0px; margin-bottom: 0px">
      <div class="row" style="justify-content: center">
        <h1 style="font-size: 50px; font-weight: bold">
          <div v-html="getTranslate(doc.title)"></div>
        </h1>
      </div>
      <div class="justify-content-center">
        <div class="section_tittle text-center">
          <h2></h2>
          <br />
          <h3 class="container">
            <div v-html="getTranslate(doc.body)"></div>
          </h3>
          <v-container>
            <v-text-field
              ref="search"
              v-model="search"
              :label="$t('search')"
              clearable
            >
            </v-text-field>
            <v-autocomplete
              v-model="selected"
              :items="allFilterList"
              chips
              full-width
              readonly
              hide-details
              hide-no-data
              @click:clear="clearAllFilter"
              clearable
              item-text="title"
              item-value="id"
              hide-selected
              multiple
              single-line
            >
              <template v-slot:selection="{ item }">
                <v-chip>
                  {{ $t(item.title) }}
                </v-chip>
              </template>
            </v-autocomplete>
            <v-toolbar>
              <v-menu open-on-hover rounded offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    outlined
                    v-bind="attrs"
                    style="margin-right: 10px"
                    v-on="on"
                  >
                    {{ $t("sort") }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group v-model="sortBy" color="primary">
                    <v-list-item v-for="(item, index) in sortList" :key="index">
                      <v-list-item-title style="text-align: left">{{
                        item.title
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-menu open-on-hover rounded offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    outlined
                    v-bind="attrs"
                    style="margin-right: 10px"
                    v-on="on"
                  >
                    {{ $t("price") }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group v-model="priceBy" color="primary">
                    <v-list-item
                      v-for="(item, index) in priceList"
                      :key="index"
                    >
                      <v-list-item-title style="text-align: left">{{
                        item.title
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-menu
                open-on-hover
                rounded
                v-if="!$vuetify.breakpoint.mobile"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    outlined
                    style="margin-right: 10px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t("careLevel") }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group v-model="careBy" color="primary">
                    <v-list-item v-for="(item, index) in careList" :key="index">
                      <v-list-item-title style="text-align: left">{{
                        $t(item.title)
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-menu
                open-on-hover
                rounded
                v-if="!$vuetify.breakpoint.mobile"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    style="margin-right: 10px"
                  >
                    {{ $t("light") }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group v-model="lightBy" color="primary">
                    <v-list-item
                      v-for="(item, index) in lightList"
                      :key="index"
                    >
                      <v-list-item-title style="text-align: left">{{
                        $t(item.title)
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-menu
                open-on-hover
                rounded
                v-if="!$vuetify.breakpoint.mobile"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    style="margin-right: 10px"
                  >
                    {{ $t("size") }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group v-model="sizeBy" color="primary">
                    <v-list-item v-for="(item, index) in sizeList" :key="index">
                      <v-list-item-title style="text-align: left">{{
                        $t(item.title)
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-spacer></v-spacer>
            </v-toolbar>
            <br />
            <v-menu
              open-on-hover
              rounded
              v-if="$vuetify.breakpoint.mobile"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  outlined
                  v-bind="attrs"
                  style="margin-right: 10px"
                  v-on="on"
                >
                  {{ $t("careLevel") }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item-group v-model="careBy" color="primary">
                  <v-list-item v-for="(item, index) in careList" :key="index">
                    <v-list-item-title style="text-align: left">{{
                      $t(item.title)
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-menu
              open-on-hover
              rounded
              v-if="$vuetify.breakpoint.mobile"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  outlined
                  v-bind="attrs"
                  style="margin-right: 10px"
                  v-on="on"
                >
                  {{ $t("light") }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item-group v-model="lightBy" color="primary">
                  <v-list-item v-for="(item, index) in lightList" :key="index">
                    <v-list-item-title style="text-align: left">{{
                      $t(item.title)
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-menu
              open-on-hover
              rounded
              v-if="$vuetify.breakpoint.mobile"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark outlined v-bind="attrs" v-on="on">
                  {{ $t("size") }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item-group v-model="sizeBy" color="primary">
                  <v-list-item v-for="(item, index) in sizeList" :key="index">
                    <v-list-item-title style="text-align: left">{{
                      $t(item.title)
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-container>
        </div>
      </div>
    </section>
    <v-row>
      <v-col
        v-for="(d, i) in productList"
        :key="i"
        class="d-flex child-flex"
        @click="goToPlantDetail(d)"
        cols="6"
        md="2"
        style="cursor: pointer; position: relative; margin-bottom: 80px"
        sm="3"
      >
        <div class="imgZoom">
          <v-img
            v-if="!!d.url || !!d.urlList"
            style="margin-top: 20px"
            :src="d.url || d.urlList[0]"
            aspect-ratio="1"
          />
        </div>
        <div class="changeColor centered">
          <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            style="font-size: 25px"
          >
            {{ d.currency | getCurrency }}{{ d.minPrice }} -
            {{ d.currency | getCurrency }}{{ d.maxPrice }}
          </v-chip>
        </div>
        <div
          class="changeColor centeredTitle"
          style="font-weight: bold; font-size: 14px"
          v-html="getTranslate(d.title)"
        ></div>
      </v-col>
    </v-row>
    <br /><br /><br />
    <br /><br /><br />
  </div>
</template>

<script>
import MyMixin from "@/mixins/mixin";
import axios from "axios";
import { Constants } from "../libs/constant";
import _ from "lodash";

export default {
  name: "Plant",
  mixins: [MyMixin],
  components: {},
  data() {
    return {
      selected: [],
      allFilterList: [],
      productList: [],
      sortBy: "",
      priceBy: "",
      careBy: "",
      sizeBy: "",
      search: "",
      lightBy: "",
      sortList: [
        { id: "NameASC", title: "Name A-Z" },
        { id: "NameDESC", title: "Name Z-A" },
        { id: "PriceLtoH", title: "Price Low to High" },
        { id: "PriceHtoL", title: "Price High to Low" },
      ],
      priceList: [
        { id: "Price0to30", title: "0-30$" },
        { id: "Price30to60", title: "30-60$" },
        { id: "Price60to100", title: "60-100$" },
        { id: "Price100Up", title: "100$ Up" },
      ],

      careList: [
        { id: "Advanced", title: "Advanced" },
        { id: "Moderate", title: "Moderate" },
        { id: "Easy", title: "Easy" },
      ],
      lightList: [
        { id: "lowLight", title: "lowLight" },
        { id: "indirectLight", title: "indirectLight" },
        { id: "directLight", title: "directLight" },
      ],
      sizeList: [
        { id: "small", title: "small" },
        { id: "medium", title: "medium" },
        { id: "large", title: "large" },
      ],
      typeList: [
        { id: "small", title: "small" },
        { id: "medium", title: "medium" },
        { id: "large", title: "large" },
      ],
      type: "",
      doc: {},
    };
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      this.$refs.search.focus();
    });
  },
  watch: {
    videoLink(val) {
      let vm = this;
      vm.videoEmbedCode = "";
      if (val && val.split("youtu.be").length === 2) {
        vm.videoEmbedCode = val.replace("youtu.be", "youtube.com/embed");
      } else if (val && val.split("/watch?v=").length > 0) {
        vm.videoEmbedCode =
          "https://www.youtube.com/embed/" +
          val.split("/watch?v=")[1].split("&list=")[0].split("&t=")[0];
      }
    },
    search() {
      this.fetchProductWithFilter();
    },
    $route() {
      let vm = this;
      let rawParam = vm.$route.params;
      vm.doc = rawParam.doc;
      this.fetchProductWithFilter();
    },
    sortBy(nVal, oVal) {
      let vm = this;
      if (oVal !== "" && oVal !== undefined) {
        vm.selected = vm.selected.filter((d) => d.id !== vm.sortList[oVal].id);
      }
      if (nVal !== "" && nVal !== undefined) {
        let newIn = vm.sortList[nVal].id;
        let existDoc = vm.selected.find((o) => o.id === newIn);
        if (!existDoc) {
          vm.selected.push(vm.sortList[nVal]);
        }
      }
      vm.fetchProductWithFilter();
    },
    priceBy(nVal, oVal) {
      let vm = this;
      if (oVal !== "" && oVal !== undefined) {
        vm.selected = vm.selected.filter((d) => d.id !== vm.priceList[oVal].id);
      }
      if (nVal !== "" && nVal !== undefined) {
        let newIn = vm.priceList[nVal].id;
        let existDoc = vm.selected.find((o) => o.id === newIn);
        if (!existDoc) {
          vm.selected.push(vm.priceList[nVal]);
        }
      }
      vm.fetchProductWithFilter();
    },
    sizeBy(nVal, oVal) {
      let vm = this;
      if (oVal !== "" && oVal !== undefined) {
        vm.selected = vm.selected.filter((d) => d.id !== vm.sizeList[oVal].id);
      }
      if (nVal !== "" && nVal !== undefined) {
        let newIn = vm.sizeList[nVal].id;
        let existDoc = vm.selected.find((o) => o.id === newIn);
        if (!existDoc) {
          vm.selected.push(vm.sizeList[nVal]);
        }
      }
      vm.fetchProductWithFilter();
    },
    careBy(nVal, oVal) {
      let vm = this;
      if (oVal !== "" && oVal !== undefined) {
        vm.selected = vm.selected.filter((d) => d.id !== vm.careList[oVal].id);
      }
      if (nVal !== "" && nVal !== undefined) {
        let newIn = vm.careList[nVal].id;
        let existDoc = vm.selected.find((o) => o.id === newIn);
        if (!existDoc) {
          vm.selected.push(vm.careList[nVal]);
        }
      }
      vm.fetchProductWithFilter();
    },
    lightBy(nVal, oVal) {
      let vm = this;
      if (oVal !== "" && oVal !== undefined) {
        vm.selected = vm.selected.filter((d) => d.id !== vm.lightList[oVal].id);
      }
      if (nVal !== "" && nVal !== undefined) {
        let newIn = vm.lightList[nVal].id;
        let existDoc = vm.selected.find((o) => o.id === newIn);
        if (!existDoc) {
          vm.selected.push(vm.lightList[nVal]);
        }
      }
      vm.fetchProductWithFilter();
    },
  },
  methods: {
    clearAllFilter() {
      let vm = this;
      vm.sortBy = "";
      vm.careBy = "";
      vm.sizeBy = "";
      vm.priceBy = "";
      vm.lightBy = "";
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    goToPlantDetail(d) {
      let vm = this;
      vm.$router.push({
        name: "plantDetail",
        params: { title: vm.getTranslate(d.title), id: d._id, doc: d },
      });
    },

    fetchProductWithFilter: _.debounce(function () {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getProductWithFilter;
      let params = {};
      if (vm.sortBy !== "" && vm.sortBy !== undefined) {
        switch (vm.sortBy) {
          case 0:
            params.sortBy = { "title.en": 1 };
            break;
          case 1:
            params.sortBy = { "title.en": -1 };
            break;
          case 2:
            params.sortBy = { minPrice: 1, maxPrice: 1 };
            break;
          case 3:
            params.sortBy = { maxPrice: -1, minPrice: -1 };
            break;
          default:
            break;
        }
      }

      if (vm.priceBy !== "" && vm.priceBy !== undefined) {
        switch (vm.priceBy) {
          case 0:
            params.price = [
              { minPrice: { $lte: 30 } },
              { maxPrice: { $lte: 30 } },
            ];
            break;
          case 1:
            params.price = [
              { minPrice: { $gte: 30, $lte: 60 } },
              { maxPrice: { $gte: 30, $lte: 60 } },
            ];
            break;
          case 2:
            params.price = [
              { minPrice: { $gte: 60, $lte: 100 } },
              { maxPrice: { $gte: 60, $lte: 100 } },
            ];
            break;
          case 3:
            params.price = [
              { minPrice: { $gte: 100 } },
              { maxPrice: { $gte: 100 } },
            ];
            break;
          default:
            break;
        }
      }

      if (vm.careBy !== "" && vm.careBy !== undefined) {
        switch (vm.careBy) {
          case 0:
            params.care = "Advanced";
            break;
          case 1:
            params.care = "Moderate";
            break;
          case 2:
            params.care = "Easy";
            break;
          default:
            break;
        }
      }
      if (vm.lightBy !== "" && vm.lightBy !== undefined) {
        switch (vm.lightBy) {
          case 0:
            params.light = "Low Light";
            break;
          case 1:
            params.light = "Indirect Light";
            break;
          case 2:
            params.light = "Direct Light";
            break;
          default:
            break;
        }
      }

      if (vm.sizeBy !== "" && vm.sizeBy !== undefined) {
        switch (vm.sizeBy) {
          case 0:
            params.size = "Small";
            break;
          case 1:
            params.size = "Medium";
            break;
          case 2:
            params.size = "Large";
            break;
          default:
            break;
        }
      }
      if (vm.doc.type === "Plant Type") {
        params.plantTypeId = vm.doc._id;
      } else if (vm.doc.type === "Plant Life Style") {
        params.plantLifeStyleId = vm.doc._id;
      } else if (vm.doc.type === "Plant Gift") {
        params.plantGiftId = vm.doc._id;
      } else if (vm.doc.type === "Plant Gift") {
        params.plantRoomId = vm.doc._id;
      }
      return new Promise((resolve, reject) => {
        axios
          .post(
            url,
            {
              branchId: Constants.branchId,
              params: params,
              q: vm.search,
            },
            { headers: { token: Constants.token } }
          )
          .then(
            (res) => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.productList = res.data.data;
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    }, 50),
  },
  created() {
    let vm = this;
    let rawParam = vm.$route.params;
    if (rawParam.doc && rawParam.doc !== null) {
      vm.doc = rawParam.doc;
    } else {
      vm.doc = {
        title: {
          en: "All Plant",
          cn: "所有植物。",
          km: "រុក្ខជាតិទាំងអស់",
        },
        body: {
          en: "",
          cn: "",
          km: "",
        },
      };
    }

    if (rawParam.type && rawParam.type !== "" && rawParam.type !== undefined) {
      if (rawParam.type === "Size" && rawParam.filter) {
        vm.sizeBy = vm.sizeList.findIndex((o) => o.id === rawParam.filter);
      } else if (rawParam.type === "Care" && rawParam.filter) {
        vm.careBy = vm.careList.findIndex((o) => o.id === rawParam.filter);
      } else if (rawParam.type === "Light" && rawParam.filter) {
        vm.lightBy = vm.lightList.findIndex((o) => o.id === rawParam.filter);
      }
    }
    this.fetchProductWithFilter();
    vm.allFilterList.push(...vm.sortList);
    vm.allFilterList.push(...vm.sizeList);
    vm.allFilterList.push(...vm.lightList);
    vm.allFilterList.push(...vm.careList);
    vm.allFilterList.push(...vm.priceList);
  },
};
</script>


<style lang="scss" scoped>
.imgZoom :hover {
  transition: transform 0.2s;
  transform: scale(1.2);
}

.changeColor:hover {
  color: darkgoldenrod;
  opacity: 1;
  cursor: pointer;
  elevation: 20deg;
}

.centered {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centeredTitle {
  position: absolute;
  top: 107%;
  left: 50%;
  transform: translate(-50%, 0%);
}
</style>

<template>
  <div>
    <hr style="size: 2px;padding-top:80px">

    <v-container :style="$vuetify.breakpoint.mobile? 'padding:0px !important;': ''">
      <section :style="$vuetify.breakpoint.mobile? 'margin-bottom: 150px' : 'padding: 20px;margin-bottom: 50px'">
        <v-row>
          <v-col cols="12" md="7" sm="7" style="padding-left: 0px !important; padding-right: 0px !important;">
            <v-img v-if="$vuetify.breakpoint.mobile" :src="urlList[index]" style="width: 100%;" aspect-ratio="1"/>
            <v-img v-if="!$vuetify.breakpoint.mobile" :src="urlList[index]" style="width: 100%" aspect-ratio="1"/>

            <br>
            <v-avatar v-for="(d,i) in urlList" :key="i"
                      @click="index=i" class="imgZoom" size="100"
                      style="cursor: pointer"
            >
              <v-img
                  :src="d"
              />
            </v-avatar>
          </v-col>
          <v-col cols="12" md="5" sm="5" :style="$vuetify.breakpoint.mobile ? '' : 'padding-left: 40px' ">
            <div class="row">
              <h1 style="font-size: 35px;font-weight: bold">
                <div v-html="getTranslate(doc.title)"></div>
              </h1>
              <br>

            </div>
            <br>
            <div style="text-align: left">
              <h2 style="color: green">{{ $t('code') }} : {{ doc.code || "" }}</h2>
            </div>
            <div style="text-align: left">
              <h2 style="color: green">{{ doc.currency | getCurrency }}{{ doc.minPrice }} -
                {{ doc.currency | getCurrency }}{{ doc.maxPrice }}</h2>
            </div>

            <br>
            <br>
            <div style="text-align: left">
              <span style="font-size: 25px">{{ $t('care') }} :</span>
              <v-chip
                  v-for="(s,i) in doc.care"
                  class="ma-2"
                  color="green"
                  :key="i"
                  style="font-size: 20px"
                  text-color="white"
              >
                {{ $t(s) }}
              </v-chip>
            </div>
            <br>
            <div style="text-align: left">
              <span style="font-size: 25px">{{ $t('size') }} :</span>
              <v-chip
                  v-for="(s,i) in doc.size"
                  class="ma-2"
                  color="blue"
                  style="font-size: 20px"

                  :key="i"
                  text-color="white"
              >
                {{ $t(s) }}

              </v-chip>
            </div>
            <br>
            <div style="text-align: left">
              <span style="font-size: 25px">{{ $t('light') }} :</span>
              <v-chip
                  v-for="(s,i) in doc.light"
                  class="ma-2"
                  color="teal"
                  style="font-size: 20px"

                  :key="i"
                  text-color="white"
              >
                {{ $t(s) }}
              </v-chip>
            </div>
            <br>
            <div style="text-align: center">
              <h2>{{ $t('pleaseContactUsForOrder') }}</h2>
              <v-btn
                  class="mx-4 blue--text"
                  icon
                  large
                  :href="dataFooter.facebookUrl || ''"
                  target="_blank"
              >
                <v-icon size="24px">
                  mdi-facebook
                </v-icon>
              </v-btn>
              <v-btn
                  class="mx-4 green--text"
                  icon
                  large
                  :href="dataFooter.twitterUrl || ''"
                  target="_blank"
              >
                <v-icon size="24px">
                  mdi-twitter
                </v-icon>
              </v-btn>
              <v-btn
                  class="mx-4 light-blue--text"
                  icon
                  large
                  :href="dataFooter.linkInUrl || ''"
                  target="_blank"
              >
                <v-icon size="24px">
                  mdi-linkedin
                </v-icon>
              </v-btn>
              <v-btn
                  class="mx-4 teal--text"
                  icon
                  large
                  :href="dataFooter.instagramUrl || ''"
                  target="_blank"
              >
                <v-icon size="24px">
                  mdi-instagram
                </v-icon>
              </v-btn>
              <v-btn
                  class="mx-4 blue--text"
                  icon
                  :href="dataFooter.telegramUrl || ''"
                  target="_blank"
              >
                <v-icon size="24px">
                  mdi-send
                </v-icon>
              </v-btn>
            </div>
            <br>
          </v-col>
          <v-col cols="12" md="12">
            <div style="text-align: left">
              <div v-html="getTranslate(doc.body)">
              </div>
            </div>

          </v-col>
        </v-row>
      </section>
    </v-container>
    <v-container>
      <div style="text-align: center">
        <h2>{{ $t("relevantPlant") }}</h2>
      </div>
      <br>
      <div class="row justify-content-center">
        <div class="section_tittle text-center">
          <h2></h2>
        </div>
      </div>
      <br>
    </v-container>
    <v-row style="margin-bottom: 100px">
      <v-col
          v-for="(d,i) in relevantProduct"
          :key="i"
          class="d-flex child-flex"
          cols="6"
          @click="changeToPlantDetail(d)"
          md="2"
          style="cursor: pointer;position: relative;margin-bottom: 80px"
          sm="2"
      >
        <div class="imgZoom">
          <v-img v-if="!!d.url || !!d.urlList" style="margin-top: 20px" :src="d.url || d.urlList[0]" aspect-ratio="1"/>

        </div>
        <div class="changeColor centered">
          <v-chip
              class="ma-2"
              color="green"
              text-color="white"
              style="font-size: 25px"
          >
            {{ d.currency | getCurrency }}{{ d.minPrice }} - {{ d.currency | getCurrency }}{{ d.maxPrice }}
          </v-chip>
        </div>
        <div class="changeColor centeredTitle" style="font-weight: bold;font-size: 14px;"
             v-html="getTranslate(d.title)"></div>

      </v-col>
    </v-row>
    <br><br><br>
    <br><br><br>
    <br><br><br>
  </div>

</template>

<script>

import MyMixin from "@/mixins/mixin"
import axios from "axios";
import {Constants} from "../libs/constant";

export default {
  name: "PlantDetail",
  mixins: [MyMixin],
  components: {},
  data() {
    return {
      doc: {},
      dataFooter: {},
      urlList: [],
      relevantProduct: [],
      index: 0,
      proId: ""
    };
  },
  mounted() {
    this.onResize();
  },
  watch: {
    videoLink(val) {
      let vm = this;
      vm.videoEmbedCode = "";
      if (val && val.split("youtu.be").length === 2) {
        vm.videoEmbedCode = val.replace("youtu.be", "youtube.com/embed");
      } else if (val && val.split("/watch?v=").length > 0) {
        vm.videoEmbedCode =
            "https://www.youtube.com/embed/" +
            val.split("/watch?v=")[1].split("&list=")[0].split("&t=")[0];
      }
    },
    $route() {
      let vm = this;
      let rawParam = vm.$route.params;
      vm.doc = rawParam.doc;
      this.fetchProduct();

    }
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight};
    },
    changeToPlantDetail(doc) {
      let vm = this;
      vm.urlList=[];
      if (vm.doc._id === doc._id) {
        vm.doc = doc;
        if (vm.doc.urlList && vm.doc.urlList.length > 0) {
          vm.urlList = vm.doc.urlList;
        }
        if (vm.doc.url) {
          vm.urlList.unshift(vm.doc.url);
        }

        vm.$vuetify.goTo(0);
        if (vm.doc) {
          vm.viewProduct();
        }
      } else {

        vm.proId=doc._id;
        vm.$router.push({name: 'plantDetail', params: {title: vm.getTranslate(doc.title), id: doc._id, doc: doc}});
        vm.doc =Object.assign({},doc);
        if (vm.doc.urlList && vm.doc.urlList.length > 0) {
          vm.urlList = vm.doc.urlList;
        }
        if (vm.doc.url) {
          vm.urlList.unshift(vm.doc.url);
        }
      }


    },
    fetchProduct() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getProduct;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          params: {}
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.relevantProduct = res.data.data;
                if (vm.proId && vm.proId !== "") {
                  vm.doc = vm.relevantProduct.find(d => d._id === vm.proId);
                  if (vm.doc.urlList && vm.doc.urlList.length > 0) {
                    vm.urlList = vm.doc.urlList;
                  }
                  if (vm.doc.url) {
                    vm.urlList.unshift(vm.doc.url);
                  }

                }
              }
            },
            error => {
              reject(error);
            }
        );
      });

    },
    viewProduct() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.viewProduct;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          id: vm.doc._id
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
              }
            },
            error => {
              reject(error);
            }
        );
      });

    },
    fetchHeaderFooter() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getHeaderFooter;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.dataFooter = res.data.data;
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
  },
  created() {
    let vm = this;

    let rawParam = vm.$route.params;
    vm.doc = rawParam.doc || {};
    if (vm.doc.title) {
      if (vm.doc.urlList && vm.doc.urlList.length > 0) {
        vm.urlList = vm.doc.urlList;
      }
      if (vm.doc.url) {
        vm.urlList.unshift(vm.doc.url);
      }
      if (vm.doc) {
        vm.viewProduct();
      }
      vm.fetchProduct();

    } else {
      vm.proId = rawParam.id;
      vm.viewProduct();
      vm.fetchProduct();

    }

    this.fetchHeaderFooter();
  },
};
</script>


<style lang="scss" scoped>

.imgZoom :hover {
  transition: transform .2s;
  transform: scale(1.2);
}

.changeColor:hover {
  color: darkgoldenrod;
  opacity: 1;
  cursor: pointer;
  elevation: 20deg;
}

.centered {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centeredTitle {
  position: absolute;
  top: 107%;
  left: 50%;
  transform: translate(-50%, 0%);

}

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr',{staticStyle:{"size":"2px","padding-top":"80px"}}),_c('section',{staticStyle:{"padding":"0px","margin-bottom":"0px"}},[_c('div',{staticClass:"row",staticStyle:{"justify-content":"center"}},[_c('h1',{staticStyle:{"font-size":"50px","font-weight":"bold"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getTranslate(_vm.doc.title))}})])]),_c('div',{staticClass:"justify-content-center"},[_c('div',{staticClass:"section_tittle text-center"},[_c('h2'),_c('br'),_c('h3',{staticClass:"container"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getTranslate(_vm.doc.body))}})]),_c('v-container',[_c('v-text-field',{ref:"search",attrs:{"label":_vm.$t('search'),"clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-autocomplete',{attrs:{"items":_vm.allFilterList,"chips":"","full-width":"","readonly":"","hide-details":"","hide-no-data":"","clearable":"","item-text":"title","item-value":"id","hide-selected":"","multiple":"","single-line":""},on:{"click:clear":_vm.clearAllFilter},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-toolbar',[_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"10px"},attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("sort"))+" ")])]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}},_vm._l((_vm.sortList),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"10px"},attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("price"))+" ")])]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.priceBy),callback:function ($$v) {_vm.priceBy=$$v},expression:"priceBy"}},_vm._l((_vm.priceList),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),(!_vm.$vuetify.breakpoint.mobile)?_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"10px"},attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("careLevel"))+" ")])]}}],null,false,118069840)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.careBy),callback:function ($$v) {_vm.careBy=$$v},expression:"careBy"}},_vm._l((_vm.careList),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(_vm.$t(item.title)))])],1)}),1)],1)],1):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"10px"},attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("light"))+" ")])]}}],null,false,1036327149)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.lightBy),callback:function ($$v) {_vm.lightBy=$$v},expression:"lightBy"}},_vm._l((_vm.lightList),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(_vm.$t(item.title)))])],1)}),1)],1)],1):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"10px"},attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("size"))+" ")])]}}],null,false,1724891350)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.sizeBy),callback:function ($$v) {_vm.sizeBy=$$v},expression:"sizeBy"}},_vm._l((_vm.sizeList),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(_vm.$t(item.title)))])],1)}),1)],1)],1):_vm._e(),_c('v-spacer')],1),_c('br'),(_vm.$vuetify.breakpoint.mobile)?_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"10px"},attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("careLevel"))+" ")])]}}],null,false,118069840)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.careBy),callback:function ($$v) {_vm.careBy=$$v},expression:"careBy"}},_vm._l((_vm.careList),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(_vm.$t(item.title)))])],1)}),1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"10px"},attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("light"))+" ")])]}}],null,false,1036327149)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.lightBy),callback:function ($$v) {_vm.lightBy=$$v},expression:"lightBy"}},_vm._l((_vm.lightList),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(_vm.$t(item.title)))])],1)}),1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("size"))+" ")])]}}],null,false,3862950793)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.sizeBy),callback:function ($$v) {_vm.sizeBy=$$v},expression:"sizeBy"}},_vm._l((_vm.sizeList),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(_vm.$t(item.title)))])],1)}),1)],1)],1):_vm._e()],1)],1)])]),_c('v-row',_vm._l((_vm.productList),function(d,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"cursor":"pointer","position":"relative","margin-bottom":"80px"},attrs:{"cols":"6","md":"2","sm":"3"},on:{"click":function($event){return _vm.goToPlantDetail(d)}}},[_c('div',{staticClass:"imgZoom"},[(!!d.url || !!d.urlList)?_c('v-img',{staticStyle:{"margin-top":"20px"},attrs:{"src":d.url || d.urlList[0],"aspect-ratio":"1"}}):_vm._e()],1),_c('div',{staticClass:"changeColor centered"},[_c('v-chip',{staticClass:"ma-2",staticStyle:{"font-size":"25px"},attrs:{"color":"green","text-color":"white"}},[_vm._v(" "+_vm._s(_vm._f("getCurrency")(d.currency))+_vm._s(d.minPrice)+" - "+_vm._s(_vm._f("getCurrency")(d.currency))+_vm._s(d.maxPrice)+" ")])],1),_c('div',{staticClass:"changeColor centeredTitle",staticStyle:{"font-weight":"bold","font-size":"14px"},domProps:{"innerHTML":_vm._s(_vm.getTranslate(d.title))}})])}),1),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }